export interface SelectedItem {
    rowId: string;
    columnId: string;
}

export interface ConfigureListsFocusContextType {
    selectedItem: SelectedItem;
    setSelectedItem: (newItem: SelectedItem) => void;
}

export interface ReferenceData {
    childListSpecMap: Record<string, string>;
    childListSpecPayload: any;
    childColumnId: string;
    childListId: string;
    childTableId: string;
    childTableViewId: string;
    childListSpecs: string;
    parentColumnId?: string;
    parentListId?: string;
    parentTableId?: string;
    parentTableViewId?: string;
    parentListSpecs?: string;
    parentListSpecMap?: Record<string, string>;
    parentListSpecPayload?: any;
}

interface Values {
    id: string;
    default: boolean;
    parentId?: string;
}

export interface ListColumn {
    referenceData: ReferenceData;
    activityExcludedColumnIds: Record<string, boolean>;
    id: string;
    rows: { id: string; name: string; colour?: string }[];
    columnName: string;
    listName: string;
    focusable: boolean;
    hasParent: boolean;
    tableName?: string;
    focusableOnOnlySelected?: boolean;
    disabled?: boolean;
    menuDisabled?: boolean;
    defaultDisabled?: boolean;
    parentTableName?: string;
    parentListName?: string;
    parentColumnName?: string;
    conditionalValues?: Record<string, Record<string, Values>>;
    values?: Record<string, Values>;
}

interface MenuReferenceData {
    parentColumnId: string;
    parentTableId: string;
    parentTableViewId: string;
    parentListId: string;
    parentListSpecs: string;
}

export interface MenuOption {
    columnName: string;
    listName: string;
    referenceData: MenuReferenceData;
    tableName?: string;
}

export interface ConfigureListColumnProps extends ListColumn {
    menuOptions: MenuOption[];
    numHeaderColumns: number;
    onListSpecChanged: Function;
}

export enum ListSpecAction {
    CELL_CHECKED = 'CELL_CHECKED',
    DEFAULT_SELECTED = 'DEFAULT_SELECTED',
    HEADER_CHECKED = 'HEADER_CHECKED',
    LINK_MODIFIED = 'LINK_MODIFIED',
    COLUMN_VISIBILITY_MODIFIED = 'COLUMN_VISIBILITY_MODIFIED',
}
